var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ESLCarouselSlide_1;
import { ESLMixinElement } from '../../esl-mixin-element/core';
import { CSSClassUtils } from '../../esl-utils/dom/class';
import { microtask } from '../../esl-utils/async/microtask';
import { ExportNs } from '../../esl-utils/environment/export-ns';
import { attr, decorate, memoize } from '../../esl-utils/decorators';
/**
 * ESLCarouselSlide component
 * @author Julia Murashko, Alexey Stsefanovich (ala'n)
 *
 * ESLCarouselSlide - a component that provides content for ESLCarousel {@link ESLCarousel}
 */
let ESLCarouselSlide = ESLCarouselSlide_1 = class ESLCarouselSlide extends ESLMixinElement {
    /** @returns slide index. */
    get index() {
        var _a;
        if (typeof ((_a = this.$carousel) === null || _a === void 0 ? void 0 : _a.indexOf) !== 'function')
            return -1;
        return this.$carousel.indexOf(this.$host);
    }
    /** @returns whether the slide is active */
    get active() {
        var _a;
        if (typeof ((_a = this.$carousel) === null || _a === void 0 ? void 0 : _a.isActive) !== 'function')
            return false;
        return this.$carousel.isActive(this.$host);
    }
    /** @returns whether the slide is in pre-active state */
    get preActive() {
        var _a;
        if (typeof ((_a = this.$carousel) === null || _a === void 0 ? void 0 : _a.isPreActive) !== 'function')
            return false;
        return this.$carousel.isPreActive(this.$host);
    }
    /** @returns whether the slide is next in navigation */
    get next() {
        var _a;
        if (typeof ((_a = this.$carousel) === null || _a === void 0 ? void 0 : _a.isNext) !== 'function')
            return false;
        return this.$carousel.isNext(this.$host);
    }
    /** @returns whether the slide is previous in navigation*/
    get prev() {
        var _a;
        if (typeof ((_a = this.$carousel) === null || _a === void 0 ? void 0 : _a.isPrev) !== 'function')
            return false;
        return this.$carousel.isPrev(this.$host);
    }
    get $carousel() {
        const type = this.constructor;
        const carouselTag = type.is.replace('-slide', '');
        return this.$host.closest(carouselTag);
    }
    connectedCallback() {
        var _a;
        if (!this.$carousel)
            return;
        ((_a = this.$carousel) === null || _a === void 0 ? void 0 : _a.addSlide) && this.$carousel.addSlide(this.$host);
        super.connectedCallback();
        this.updateA11y();
        this.updateActiveState();
    }
    disconnectedCallback() {
        var _a, _b;
        // A disconnected callback is not directly related to slide removal from the carousel
        // e.g. carousel itself can be removed from the DOM so child slides behave accordingly
        ((_a = this.$carousel) === null || _a === void 0 ? void 0 : _a.update()) && ((_b = this.$carousel) === null || _b === void 0 ? void 0 : _b.update());
        memoize.clear(this, '$carousel');
        super.disconnectedCallback();
    }
    attributeChangedCallback(attrName, oldVal, newVal) {
        if (attrName === 'active')
            this.updateActiveState();
    }
    /** Updates initial A11y attributes */
    updateA11y() {
        this.$$attr('role', 'listitem');
        if (!this.$host.hasAttribute('aria-roledescription')) {
            this.$$attr('aria-roledescription', 'slide');
        }
        if (!this.$host.hasAttribute('aria-label')) {
            this.$$attr('aria-label', `Slide ${this.index + 1}`);
        }
    }
    /** Updates A11y attributes related to active state */
    updateActiveState() {
        var _a;
        this.$$attr('aria-hidden', String(!this.active));
        if (!((_a = this.$carousel) === null || _a === void 0 ? void 0 : _a.hasAttribute(ESLCarouselSlide_1.NO_INERT_MARKER))) {
            this.$$attr('inert', !this.active);
        }
        if (!this.$carousel)
            return;
        if (this.$carousel.$container) {
            CSSClassUtils.toggle(this.$carousel.$container, this.containerClass, this.active, this.$host);
        }
        if (!this.active)
            this.blurIfInactive();
    }
    blurIfInactive() {
        var _a;
        if (this.active || !this.$host.contains(document.activeElement))
            return;
        (_a = this.$carousel) === null || _a === void 0 ? void 0 : _a.focus({ preventScroll: true });
    }
};
ESLCarouselSlide.is = 'esl-carousel-slide';
ESLCarouselSlide.observedAttributes = ['active'];
/** Carousel marker to omit `inert` attribute on slides */
ESLCarouselSlide.NO_INERT_MARKER = 'no-inert';
__decorate([
    attr()
], ESLCarouselSlide.prototype, "containerClass", void 0);
__decorate([
    memoize()
], ESLCarouselSlide.prototype, "$carousel", null);
__decorate([
    decorate(microtask)
], ESLCarouselSlide.prototype, "blurIfInactive", null);
ESLCarouselSlide = ESLCarouselSlide_1 = __decorate([
    ExportNs('Carousel.Slide')
], ESLCarouselSlide);
export { ESLCarouselSlide };
