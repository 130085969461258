var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { BaseProvider, PlayerStates } from '../core/esl-media-provider';
import { randUID } from '../../esl-utils/misc/uid';
/**
 * Simple Basic Iframe provider for {@link ESLMedia}
 * @author Alexey Stsefanovich (ala'n)
 */
let IframeBasicProvider = class IframeBasicProvider extends BaseProvider {
    constructor() {
        super(...arguments);
        this._state = PlayerStates.UNINITIALIZED;
    }
    static parseUrl(url) {
        try {
            if (!url)
                return null;
            const { protocol } = new URL(url);
            if (protocol !== 'http:' && protocol !== 'https:')
                return null;
            return { mediaSrc: url };
        }
        catch (_a) {
            return null;
        }
    }
    buildIframe() {
        const el = document.createElement('iframe');
        el.id = 'esl-media-iframe-' + randUID();
        el.className = 'esl-media-inner esl-media-iframe';
        el.title = this.config.title;
        el.setAttribute('aria-label', this.config.title);
        el.setAttribute('frameborder', '0');
        el.setAttribute('tabindex', this.config.focusable ? '0' : '-1');
        el.setAttribute('scrolling', 'no');
        el.setAttribute('allowfullscreen', 'yes');
        el.toggleAttribute('playsinline', this.config.playsinline);
        el.src = this.config.mediaSrc || '';
        return el;
    }
    bind() {
        if (this._state !== PlayerStates.UNINITIALIZED)
            return;
        this._ready = new Promise((resolve, reject) => {
            this._el = this.buildIframe();
            this._el.onload = () => resolve(this);
            this._el.onerror = (e) => reject(e);
            this._state = PlayerStates.UNSTARTED;
            this.component.appendChild(this._el);
        });
        this._ready.then(() => {
            this._state = PlayerStates.PLAYING;
            this.component._onReady();
            this.component._onPlay();
        });
        this._ready.catch((e) => this.component._onError(e));
    }
    unbind() {
        this.component._onDetach();
        this._state = PlayerStates.UNINITIALIZED;
        super.unbind();
    }
    get ready() {
        return Promise.resolve();
    }
    focus() {
        if (this._el && this._el.contentWindow) {
            this._el.contentWindow.focus();
        }
    }
    get state() {
        return this._state;
    }
    get duration() {
        return 0;
    }
    get currentTime() {
        return 0;
    }
    get defaultAspectRatio() {
        return 0;
    }
    seekTo(pos) {
        console.error('[ESLMedia] Unsupported action: can not execute seekTo on abstract iframe provider');
    }
    play() {
        if (this.state === PlayerStates.UNINITIALIZED) {
            this.bind();
        }
    }
    pause() {
        this.unbind();
    }
    stop() {
        this.unbind();
    }
};
IframeBasicProvider.providerName = 'iframe';
IframeBasicProvider = __decorate([
    BaseProvider.register
], IframeBasicProvider);
export { IframeBasicProvider };
