import { ESLMediaProviderRegistry } from './esl-media-registry';
export var PlayerStates;
(function (PlayerStates) {
    PlayerStates[PlayerStates["BUFFERING"] = 3] = "BUFFERING";
    PlayerStates[PlayerStates["ENDED"] = 0] = "ENDED";
    PlayerStates[PlayerStates["PAUSED"] = 2] = "PAUSED";
    PlayerStates[PlayerStates["PLAYING"] = 1] = "PLAYING";
    PlayerStates[PlayerStates["UNSTARTED"] = -1] = "UNSTARTED";
    PlayerStates[PlayerStates["VIDEO_CUED"] = 5] = "VIDEO_CUED";
    PlayerStates[PlayerStates["UNINITIALIZED"] = -2] = "UNINITIALIZED";
})(PlayerStates || (PlayerStates = {}));
/**
 * BaseProvider class for media API providers
 * @author Alexey Stsefanovich (ala'n), Yuliya Adamskaya, Natallia Harshunova
 */
export class BaseProvider {
    static parseUrl(url) {
        return null;
    }
    static parseConfig(component) {
        const { loop, muted, controls, autoplay, title, preload, playsinline, mediaId, mediaSrc, startTime, focusable } = component;
        const config = { loop, muted, controls, autoplay, title, preload, playsinline, startTime, focusable };
        if (mediaId)
            Object.assign(config, { mediaId });
        if (mediaSrc)
            Object.assign(config, { mediaSrc });
        return config;
    }
    constructor(component, config) {
        this.config = config;
        this.component = component;
    }
    /** Wraps _ready promise */
    get ready() {
        if (!this._ready) {
            const res = Promise.reject('Not Initialized');
            res.catch((e) => console.log('Rejected Media Operation: ', e));
            return res;
        }
        return this._ready;
    }
    /** Unbind the provider instance from the component */
    unbind() {
        Array.from(this.component.querySelectorAll('.esl-media-inner'))
            .forEach((el) => el.parentNode && el.parentNode.removeChild(el));
    }
    /** Provider name */
    get name() {
        return this.constructor.providerName;
    }
    /** Set focus to the inner content */
    focus() {
        var _a;
        (_a = this._el) === null || _a === void 0 ? void 0 : _a.focus();
    }
    onConfigChange(param, value) {
        this.config[param] = value;
    }
    /** Set size for inner content */
    setSize(width, height) {
        if (!this._el)
            return;
        this._el.style.setProperty('width', width === 'auto' ? null : `${width}px`);
        this._el.style.setProperty('height', height === 'auto' ? null : `${height}px`);
    }
    setAspectRatio(aspectRatio) {
        var _a;
        (_a = this._el) === null || _a === void 0 ? void 0 : _a.style.setProperty('aspect-ratio', aspectRatio > 0 ? `${aspectRatio}` : null);
    }
    /**
     * Executes toggle action:
     * If the player is PAUSED then it starts playing otherwise it pause playing
     */
    toggle() {
        if ([PlayerStates.PAUSED, PlayerStates.UNSTARTED, PlayerStates.VIDEO_CUED].includes(this.state)) {
            return this.play();
        }
        else {
            return this.pause();
        }
    }
    /** Executes onConfigChange action when api is ready */
    onSafeConfigChange(param, value) {
        this.ready.then(() => this.onConfigChange(param, value));
    }
    /** Executes seekTo action when api is ready */
    safeSeekTo(pos) {
        return this.ready.then(() => this.seekTo(pos));
    }
    /** Executes play when api is ready */
    safePlay() {
        return this.ready.then(() => this.play());
    }
    /** Executes pause when api is ready */
    safePause() {
        return this.ready.then(() => this.pause());
    }
    /**
     * Executes stop when api is ready
     * @returns Promise
     */
    safeStop() {
        return this.ready.then(() => this.stop());
    }
    /**
     * Executes toggle when api is ready
     * @returns Promise
     */
    safeToggle() {
        return this.ready.then(() => this.toggle());
    }
    static register(provider) {
        provider = provider || this;
        if (provider === BaseProvider)
            throw new Error('`BaseProvider` can\'t be registered.');
        if (!((provider === null || provider === void 0 ? void 0 : provider.prototype) instanceof BaseProvider))
            throw new Error('Provider should be instanceof `BaseProvider`');
        ESLMediaProviderRegistry.instance.register(provider);
    }
}
