var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ESLBaseElement } from '../../esl-base-element/core';
import { ExportNs } from '../../esl-utils/environment/export-ns';
import { isElement } from '../../esl-utils/dom/api';
import { CSSClassUtils } from '../../esl-utils/dom/class';
import { SPACE, PAUSE } from '../../esl-utils/dom/keys';
import { prop, attr, boolAttr, listen } from '../../esl-utils/decorators';
import { debounce } from '../../esl-utils/async';
import { parseAspectRatio, parseBoolean } from '../../esl-utils/misc/format';
import { ESLMediaQuery } from '../../esl-media-query/core';
import { ESLResizeObserverTarget } from '../../esl-event-listener/core';
import { ESLTraversingQuery } from '../../esl-traversing-query/core';
import { getIObserver } from './esl-media-iobserver';
import { PlayerStates } from './esl-media-provider';
import { ESLMediaProviderRegistry } from './esl-media-registry';
import { MediaGroupRestrictionManager } from './esl-media-manager';
const isLazyAttr = (v) => ['auto', 'manual', 'none'].includes(v);
const parseLazyAttr = (v) => isLazyAttr(v) ? v : 'auto';
/**
 * ESLMedia - custom element, that provides an ability to add and configure media (video / audio)
 * using a single tag as well as work with external providers using simple native-like API.
 *
 * @author Alexey Stsefanovich (ala'n), Yuliya Adamskaya
 */
let ESLMedia = class ESLMedia extends ESLBaseElement {
    constructor() {
        super(...arguments);
        this.deferredReinitialize = debounce(() => this.reinitInstance());
    }
    /**
     * Map object with possible Player States, values:
     * BUFFERING, ENDED, PAUSED, PLAYING, UNSTARTED, VIDEO_CUED, UNINITIALIZED
     */
    static get PLAYER_STATES() {
        return PlayerStates;
    }
    static supports(name) {
        return ESLMediaProviderRegistry.instance.has(name);
    }
    connectedCallback() {
        super.connectedCallback();
        if (!this.hasAttribute('role')) {
            this.setAttribute('role', 'application');
        }
        this.innerHTML += '<!-- Inner Content, do not modify it manually -->';
        this.deferredReinitialize();
        this.reattachViewportConstraint();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.detachViewportConstraint();
        this._provider && this._provider.unbind();
    }
    attributeChangedCallback(attrName, oldVal, newVal) {
        if (!this.connected || oldVal === newVal)
            return;
        switch (attrName) {
            case 'media-id':
            case 'media-src':
            case 'media-type':
            case 'start-time':
                this.deferredReinitialize();
                break;
            case 'lazy':
                this.reattachViewportConstraint();
                this.deferredReinitialize();
                break;
            case 'loop':
            case 'muted':
            case 'controls':
                this._provider && this._provider.onSafeConfigChange(attrName, newVal !== null);
                break;
            case 'fill-mode':
            case 'aspect-ratio':
                this.$$on(this._onResize);
                this._onResize();
                break;
            case 'play-in-viewport':
                this.reattachViewportConstraint();
                break;
            case 'load-condition':
                this.$$on(this._onConditionChange);
                this.deferredReinitialize();
                break;
        }
    }
    canActivate() {
        return this.lazy === 'none' && this.conditionQuery.matches;
    }
    reinitInstance() {
        console.debug('[ESL] Media reinitialize ', this);
        this._provider && this._provider.unbind();
        this._provider = null;
        if (this.canActivate()) {
            this._provider = ESLMediaProviderRegistry.instance.createFor(this);
            if (this._provider) {
                this._provider.bind();
                console.debug('[ESL] Media provider bound', this._provider);
            }
            else {
                this._onError();
            }
        }
        this.updateContainerMarkers();
    }
    updateContainerMarkers() {
        const $target = ESLTraversingQuery.first(this.loadConditionClassTarget, this);
        $target && CSSClassUtils.toggle($target, this.loadConditionClass, this.conditionQuery.matches);
    }
    /** Seek to given position of media */
    seekTo(pos) {
        return this._provider && this._provider.safeSeekTo(pos);
    }
    /**
     * Start playing media
     * @param allowActivate - allows to remove manual lazy loading restrictions
     */
    play(allowActivate = false) {
        if (!this.ready && allowActivate) {
            this.lazy = 'none';
            this.deferredReinitialize.cancel();
            this.reinitInstance();
        }
        if (!this.canActivate())
            return null;
        return this._provider && this._provider.safePlay();
    }
    /** Pause playing media */
    pause() {
        return this._provider && this._provider.safePause();
    }
    /** Stop playing media */
    stop() {
        return this._provider && this._provider.safeStop();
    }
    /** Toggle play/pause state of the media */
    toggle() {
        return this._provider && this._provider.safeToggle();
    }
    /** Focus inner player **/
    focusPlayer() {
        this._provider && this._provider.focus();
    }
    // media live-cycle handlers
    _onReady() {
        this.toggleAttribute('ready', true);
        this.toggleAttribute('error', false);
        this.updateReadyClass();
        this.$$fire(this.READY_EVENT);
        this._onResize();
    }
    _onError(detail, setReadyState = true) {
        this.toggleAttribute('ready', true);
        this.toggleAttribute('error', true);
        this.$$fire(this.ERROR_EVENT, { detail });
        setReadyState && this.$$fire(this.READY_EVENT);
    }
    _onDetach() {
        this.removeAttribute('active');
        this.removeAttribute('ready');
        this.removeAttribute('played');
        this.updateReadyClass();
        this.$$fire(this.DETACHED_EVENT);
    }
    _onPlay() {
        if (this.autofocus)
            this.focus();
        this.toggleAttribute('active', true);
        this.toggleAttribute('played', true);
        this.$$fire(this.PLAY_EVENT);
        MediaGroupRestrictionManager.registerPlay(this);
        this._onResize();
    }
    _onPaused() {
        this.removeAttribute('active');
        this.$$fire(this.PAUSED_EVENT);
        MediaGroupRestrictionManager.unregister(this);
    }
    _onEnded() {
        this.removeAttribute('active');
        this.$$fire(this.ENDED_EVENT);
        MediaGroupRestrictionManager.unregister(this);
    }
    _onResize() {
        if (!this._provider)
            return;
        const { actualAspectRatio } = this;
        this.$$attr('wide', this.offsetWidth / this.offsetHeight > actualAspectRatio);
        this._provider.setAspectRatio(actualAspectRatio);
    }
    _onRefresh(e) {
        const { target } = e;
        if (isElement(target) && target.contains(this))
            this._onResize();
    }
    _onRegistryStateChange(e) {
        if (e.isRelates(this.mediaType))
            this.reinitInstance();
    }
    _onConditionChange() {
        this.deferredReinitialize();
    }
    _onKeydown(e) {
        if (e.target !== this)
            return;
        if ([SPACE, PAUSE].includes(e.key)) {
            e.preventDefault();
            e.stopPropagation();
            this.toggle();
        }
    }
    /** Update ready class state */
    updateReadyClass() {
        const target = ESLTraversingQuery.first(this.readyClassTarget, this);
        target && CSSClassUtils.toggle(target, this.readyClass, this.ready);
    }
    /** Applied provider */
    get providerType() {
        return this._provider ? this._provider.name : '';
    }
    /** Current player state, see {@link ESLMedia.PLAYER_STATES} values */
    get state() {
        return this._provider ? this._provider.state : PlayerStates.UNINITIALIZED;
    }
    /** Duration of the media resource */
    get duration() {
        return this._provider ? this._provider.duration : 0;
    }
    /** Current time of media resource */
    get currentTime() {
        return this._provider ? this._provider.currentTime : 0;
    }
    /** Set current time of media resource */
    set currentTime(time) {
        (this._provider) && this._provider.safeSeekTo(time);
    }
    /** ESLMediaQuery to limit ESLMedia loading */
    get conditionQuery() {
        return ESLMediaQuery.for(this.loadCondition);
    }
    /** Fill mode should be handled for element */
    get fillModeEnabled() {
        return this.fillMode === 'cover' || this.fillMode === 'inscribe';
    }
    /** Used resource aspect ratio forced by attribute or returned by provider */
    get actualAspectRatio() {
        if (this.aspectRatio && this.aspectRatio !== 'auto')
            return parseAspectRatio(this.aspectRatio);
        return this._provider ? this._provider.defaultAspectRatio : 0;
    }
    reattachViewportConstraint() {
        this.detachViewportConstraint();
        if (!this.playInViewport && this.lazy !== 'auto')
            return;
        getIObserver().observe(this);
    }
    detachViewportConstraint() {
        const observer = getIObserver(true);
        observer && observer.unobserve(this);
    }
};
ESLMedia.is = 'esl-media';
ESLMedia.observedAttributes = [
    'load-condition',
    'media-type',
    'media-id',
    'media-src',
    'fill-mode',
    'aspect-ratio',
    'play-in-viewport',
    'muted',
    'loop',
    'controls',
    'lazy',
    'start-time'
];
__decorate([
    prop('esl:media:ready')
], ESLMedia.prototype, "READY_EVENT", void 0);
__decorate([
    prop('esl:media:error')
], ESLMedia.prototype, "ERROR_EVENT", void 0);
__decorate([
    prop('esl:media:play')
], ESLMedia.prototype, "PLAY_EVENT", void 0);
__decorate([
    prop('esl:media:paused')
], ESLMedia.prototype, "PAUSED_EVENT", void 0);
__decorate([
    prop('esl:media:ended')
], ESLMedia.prototype, "ENDED_EVENT", void 0);
__decorate([
    prop('esl:media:detached')
], ESLMedia.prototype, "DETACHED_EVENT", void 0);
__decorate([
    prop('esl:media:managedpause')
], ESLMedia.prototype, "MANAGED_PAUSE_EVENT", void 0);
__decorate([
    attr()
], ESLMedia.prototype, "mediaId", void 0);
__decorate([
    attr()
], ESLMedia.prototype, "mediaSrc", void 0);
__decorate([
    attr()
], ESLMedia.prototype, "mediaType", void 0);
__decorate([
    attr()
], ESLMedia.prototype, "group", void 0);
__decorate([
    attr()
], ESLMedia.prototype, "fillMode", void 0);
__decorate([
    attr()
], ESLMedia.prototype, "aspectRatio", void 0);
__decorate([
    attr({ parser: parseLazyAttr, defaultValue: 'none' })
], ESLMedia.prototype, "lazy", void 0);
__decorate([
    boolAttr()
], ESLMedia.prototype, "autoplay", void 0);
__decorate([
    boolAttr()
], ESLMedia.prototype, "autofocus", void 0);
__decorate([
    boolAttr()
], ESLMedia.prototype, "muted", void 0);
__decorate([
    boolAttr()
], ESLMedia.prototype, "loop", void 0);
__decorate([
    boolAttr()
], ESLMedia.prototype, "controls", void 0);
__decorate([
    boolAttr()
], ESLMedia.prototype, "playsinline", void 0);
__decorate([
    boolAttr()
], ESLMedia.prototype, "playInViewport", void 0);
__decorate([
    attr({ parser: parseInt })
], ESLMedia.prototype, "startTime", void 0);
__decorate([
    attr({ parser: parseBoolean, defaultValue: ($this) => $this.controls })
], ESLMedia.prototype, "focusable", void 0);
__decorate([
    attr({ defaultValue: 'auto' })
], ESLMedia.prototype, "preload", void 0);
__decorate([
    attr()
], ESLMedia.prototype, "readyClass", void 0);
__decorate([
    attr()
], ESLMedia.prototype, "readyClassTarget", void 0);
__decorate([
    attr({ defaultValue: 'all' })
], ESLMedia.prototype, "loadCondition", void 0);
__decorate([
    attr()
], ESLMedia.prototype, "loadConditionClass", void 0);
__decorate([
    attr({ defaultValue: '::parent' })
], ESLMedia.prototype, "loadConditionClassTarget", void 0);
__decorate([
    boolAttr({ readonly: true })
], ESLMedia.prototype, "ready", void 0);
__decorate([
    boolAttr({ readonly: true })
], ESLMedia.prototype, "active", void 0);
__decorate([
    boolAttr({ readonly: true })
], ESLMedia.prototype, "played", void 0);
__decorate([
    boolAttr({ readonly: true })
], ESLMedia.prototype, "error", void 0);
__decorate([
    boolAttr({ readonly: true })
], ESLMedia.prototype, "wide", void 0);
__decorate([
    listen({
        event: 'resize',
        target: ESLResizeObserverTarget.for,
        condition: ($this) => $this.fillModeEnabled
    })
], ESLMedia.prototype, "_onResize", null);
__decorate([
    listen({
        event: ($this) => $this.REFRESH_EVENT,
        target: window
    })
], ESLMedia.prototype, "_onRefresh", null);
__decorate([
    listen({
        event: 'change',
        target: () => ESLMediaProviderRegistry.instance
    })
], ESLMedia.prototype, "_onRegistryStateChange", null);
__decorate([
    listen({
        event: 'change',
        target: ($this) => $this.conditionQuery
    })
], ESLMedia.prototype, "_onConditionChange", null);
__decorate([
    listen('keydown')
], ESLMedia.prototype, "_onKeydown", null);
ESLMedia = __decorate([
    ExportNs('Media')
], ESLMedia);
export { ESLMedia };
