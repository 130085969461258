import { BaseProvider, PlayerStates } from '../../core/esl-media-provider';
/**
 * Simple Native Media API provider for {@link ESLMedia}
 * @author Yuliya Adamskaya, Alexey Stsefanovich (ala'n)
 */
export class HTMLMediaProvider extends BaseProvider {
    static parseUrl(mediaSrc) {
        if (this.urlPattern.test(mediaSrc)) {
            return { mediaSrc };
        }
        return null;
    }
    static applyElementSettings(el, cfg) {
        el.classList.add('esl-media-inner');
        el.autoplay = cfg.autoplay;
        el.preload = cfg.preload || 'auto';
        el.loop = cfg.loop;
        el.muted = cfg.muted;
        el.controls = cfg.controls;
        el.tabIndex = cfg.focusable ? 0 : -1;
        el.toggleAttribute('playsinline', cfg.playsinline);
        return el;
    }
    onConfigChange(param, value) {
        super.onConfigChange(param, value);
        HTMLMediaProvider.applyElementSettings(this._el, this.config);
    }
    bind() {
        this._el = this.createElement();
        HTMLMediaProvider.applyElementSettings(this._el, this.config);
        this.component.appendChild(this._el);
        this.bindListeners();
    }
    bindListeners() {
        this._el.addEventListener('loadedmetadata', () => this.component._onReady());
        this._el.addEventListener('play', () => this.component._onPlay());
        this._el.addEventListener('pause', () => this.component._onPaused());
        this._el.addEventListener('ended', () => this.component._onEnded());
        this._el.addEventListener('error', (e) => this.component._onError(e));
    }
    unbind() {
        this.component._onDetach();
        super.unbind();
    }
    get ready() {
        return Promise.resolve();
    }
    get src() {
        return `${this.config.mediaSrc}${this.config.startTime ? `#t=${this.config.startTime}` : ''}`;
    }
    get state() {
        if (!this._el)
            return PlayerStates.UNINITIALIZED;
        if (this._el.ended)
            return PlayerStates.ENDED;
        if (!this._el.played || !this._el.played.length)
            return PlayerStates.UNSTARTED;
        if (this._el.paused)
            return PlayerStates.PAUSED;
        return PlayerStates.PLAYING;
    }
    get duration() {
        return this._el ? this._el.duration : 0;
    }
    get currentTime() {
        return this._el ? this._el.currentTime : 0;
    }
    seekTo(pos) {
        this._el.currentTime = pos;
    }
    play() {
        return this._el.play();
    }
    pause() {
        this._el.pause();
    }
    stop() {
        this._el.pause();
        this._el.currentTime = 0;
    }
}
